import { useContext, useEffect, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { DownOutlined, EditOutlined, ReloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  ESIGNATURE_CONTENT_DISPLAY_OPTIONS,
  ESIGNATURE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { Header, Link, Pagination, Table } from "@cloudscape-design/components";
import { eSignatureResponse } from "../../redux/api/signaturerequest/types";
import { Images } from "../../common/images";
import IncredableDateFormat from "../../common/incredable-date-format";
import useSignatureFilter from "../../table-filters/e-signature-filters";
import { useLocalStorage } from "../../common/localStorage";
import {
  useResendSignatureMutation,
  useVoidSignatureMutation,
} from "../../redux/api/signaturerequest/signaturerequest";
import { SpinnerContext } from "../../context/spinner/spinner";
import IncredableButton from "../../components/button";
import { RenderRecipient } from "./signature-detail";
export default function EsignatureListV2() {
  const [selectedRows, setSelectedRows] = useState<eSignatureResponse[]>();
  const {
    TableFilters,
    filteredEsignature = [],
    eSignatureTotalRecords,
    UseQueryHookResult: {
      reload,
      isFetching,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      sortingDetails,
      setSortingDetails,
    },
  } = useSignatureFilter();
  const [voidSignature] = useVoidSignatureMutation();
  const [resendSignature] = useResendSignatureMutation();
  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-eSignatureRequest-Table-Preferences",
    ESIGNATURE_TABLE_DEFAULT_PREFERENCES
  );
  useEffect(() => {
    if (!!tablePreferences?.pageSize) setPageSize(tablePreferences?.pageSize);
  }, []);
  const { items, collectionProps, paginationProps } = useCollection(
    filteredEsignature,
    {
      pagination: { pageSize: pageSize },
    }
  );
  return (
      <Table
        loading={isFetching}
        onSelectionChange={({ detail }) => {
          const selectedItem = detail?.selectedItems[0];
          setSelectedRows(selectedItem ? [selectedItem] : []);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={!!selectedRows ? selectedRows : []}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "id",
            header: <div>ID</div>,
            cell: (item) => <span>{item.id}</span>,
          },
          {
            id: "subject",
            header: <div>Subject</div>,
            cell: (item) => (
              <Link href={`/requests/${item.id}`}>{item.subject}</Link>
            ),
          },
          {
            id: "provider",
            header: <div>Provider</div>,
            cell: (item) => {
              const items = item.recipients?.find((r) => r.primary === true);
              const providerName =
                !!items && !!items.provider
                  ? `${items.provider?.firstName} ${items.provider.lastName}`
                  : !!items?.admin
                  ? `${items.admin?.firstName} ${items.admin.lastName}`
                  : !!items?.emailAddress
                  ? items?.emailAddress
                  : "-";
              return <Typography.Text> {providerName}</Typography.Text>;
            },
          },
          {
            id: "subject",
            header: <div>Subject</div>,
            cell: (item) => (
              <Link href={`/requests/${item.id}`}>{item.subject}</Link>
            ),
          },
          {
            id: "provider",
            header: <div>Provider</div>,
            cell: (item) => {
              const items = item.recipients?.find((r) => r.primary === true);
              const providerName =
                !!items && !!items.provider
                  ? `${items.provider?.firstName} ${items.provider.lastName}`
                  : !!items?.admin
                  ? `${items.admin?.firstName} ${items.admin.lastName}`
                  : !!items?.emailAddress
                  ? items?.emailAddress
                  : "-";
              return <Typography.Text> {providerName}</Typography.Text>;
            },
          },
          {
            id: "status",
            header: <div>Status</div>,
            cell: (item) => (
              <span style={{ textTransform: "capitalize" }}>
                {item?.status}
              </span>
            ),
            sortingField: "status",
          },
          {
            id: "completed_date",
            header: <div>Signed Date</div>,
            cell: (item) => (
              <span>{IncredableDateFormat(item.completedDate)}</span>
            ),
            sortingField: "completedDate",
          },
          {
            id: "attachment",
            header: <div>File Name</div>,
            cell: (item) => {
              if (!item?.attachment?.name)
                return <Typography.Text>-</Typography.Text>;
              return <span>{item?.attachment?.name || "-"}</span>;
            },
          },
          {
            id: "primary_recipient",
            header: "Primary Recipient",
            cell: (item) => (
              <RenderRecipient
                recipeint={item?.recipients?.find((r) => r.primary === true)}
              />
            ),
            sortingField: "createdDate",
          },
          {
            id: "created_date",
            header: <div>Created Date</div>,
            cell: (item) => (
              <span>{IncredableDateFormat(item.createdDate)}</span>
            ),
            sortingField: "createdDate",
          },
          {
            id: "current_recipient",
            header: "Current Recipient",
            cell: (item) => (
              <RenderRecipient
                recipeint={item?.recipientGroupByStatus?.current}
              />
            ),
          },
          {
            id: "readyForms",
            header: <div>Ready Forms</div>,
            cell: (item) => <span>{item?.digitalForm?.name}</span>,
          },
          {
            id: "recipients",
            header: "Number Of Recipient",
            cell: (item) => <span>{item?.recipients?.length}</span>,
          },
        ]}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            currentPageIndex={pageNumber + 1}
            onChange={({ detail }) => {
              setPageNumber(detail.currentPageIndex - 1);
            }}
            pagesCount={
              !!tablePreferences?.pageSize && tablePreferences?.pageSize !== 0
                ? Math.ceil(eSignatureTotalRecords / tablePreferences?.pageSize)
                : 1
            }
          />
        }
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              if (preferences.pageSize !== tablePreferences.pageSize) {
                preferences.pageSize && setPageSize(preferences.pageSize);
              }
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={ESIGNATURE_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        filter={TableFilters}
        header={
          <Header
            counter={`(${filteredEsignature?.length})`}
            actions={
              <Space size="middle">
                <Button
                  onClick={() => {
                    !!reload && reload();
                  }}
                >
                  <ReloadOutlined />
                </Button>
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        disabled: selectedRows?.length !== 1,
                        icon: <EditOutlined />,
                        onClick: () => {
                          navigate(`/requests/${selectedRows?.at(0)?.id}`);
                        },
                      },
                    ],
                  }}
                >
                  <Button>
                    Actions
                    <DownOutlined />
                  </Button>
                </Dropdown>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2B6BE6",
                    },
                  }}
                >
                  <Space>
                    <IncredableButton
                      icon={<img src={Images.voidSign} />}
                      size="middle"
                      onClick={async () => {
                        spinnerContext?.showSpinner();
                        await voidSignature({
                          signatureId: selectedRows?.at(0)?.id + "",
                        });
                        setSelectedRows([]);
                      }}
                      disabled={
                        selectedRows?.at(0)?.status === "voided"
                          ? true
                          : selectedRows?.at(0)?.status === "completed"
                          ? true
                          : selectedRows?.at(0)?.status === "pending"
                          ? false
                          : true
                      }
                      style={{
                        color: "#598BEB",
                        border: "2px solid #598BEB",
                        fontWeight: "600",
                        boxShadow: "none",
                      }}
                      iconPosition={"start"}
                    >
                      Void Signature
                    </IncredableButton>
                    <IncredableButton
                      onClick={async () => {
                        spinnerContext?.showSpinner();
                        await resendSignature({
                          signatureId: selectedRows?.at(0)?.id + "",
                        });
                        setSelectedRows([]);
                      }}
                      type="primary"
                      icon={<img src={Images.resendSign} />}
                      size="middle"
                      disabled={
                        selectedRows?.at(0)?.status === "voided"
                          ? true
                          : selectedRows?.at(0)?.status === "completed"
                          ? true
                          : selectedRows?.at(0)?.status === "pending"
                          ? false
                          : true
                      }
                      style={{
                        backgroundColor: "#598BEB",
                        border: "2px solid #598BEB",
                        boxShadow: "none",
                      }}
                    >
                      Resend Signature
                    </IncredableButton>
                  </Space>
                </ConfigProvider>
              </Space>
            }
          >
            e - Signatures
          </Header>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
  );
}
