import { useEffect } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { captureException } from "@sentry/react";
import { PATH_AUTH } from "./path";
import { Login } from "../pages";
import AuthGuard from "../gaurds/AuthGaurd";
import IncredableLayout from "../layout/incredable-layout";
import ProviderDetails from "../provider/providerDetails";
import Dashboard from "../dashboard";
import PersonalInfo from "../provider/providerDetails/personalInfo";
import AddProvider from "../provider/modify/add-provider";
import EditProvider from "../provider/modify/edit-provider";
import FacilityList from "../facility/facility-list";
import IncredableToast from "../context/toast/incredable-toast";
import IncredableSpinner from "../context/spinner/spinner";
import AddDocument from "../document/modify/add-document";
import UploadMultipleDocuments from "../document/uploadMultipleDocuments";
import ProfessionalInfo from "../provider/providerDetails/professionalInfo";
import PermissionGaurd from "../gaurds/PermissionGaurd";
import EditProviderAffiliation from "../provider/modify/edit-provideraffiliation";
import EditProviderDEA from "../provider/modify/edit-providerdea";
import EditProviderEducation from "../provider/modify/edit-providereducation";
import EditProviderLiabilityInsurance from "../provider/modify/edit-providerliabilityinsurance";
import EditProviderLicense from "../provider/modify/edit-providerlicense";
import EditProviderPeerReference from "../provider/modify/edit-providerpeerreference";
import EditProviderSpecialtyInfo from "../provider/modify/edit-providerspecialtyinfo";
import EditProviderThirdPartyLogin from "../provider/modify/edit-providerthirdpartylogin";
import EditProviderWorkHistory from "../provider/modify/edit-providerworkhistory";
import ReadyFormsList from "../digitalforms";
import AddDigitalform from "../digitalforms/add-digitalform";
import EditDigitalform from "../digitalforms/edit-digitalform";
import WorkFlowList from "../workflow/workflow-list";
import AddWorkflow from "../workflow/modify/add-workflow";
import EditWorkflow from "../workflow/modify/edit-workflow";
import EditProviderPersonalInfo from "../provider/modify/edit-providerpersonalinfo";
import AddFacility from "../facility/modify/add-facility";
import EditFacility from "../facility/modify/edit-facility";
import ContractsList from "../contracts/contracts-list";
import AddContract from "../contracts/modify/add-contract";
import EditContract from "../contracts/modify/edit-contract";
import EditProviderProfessionalInfo from "../provider/modify/edit-providerprofessionalInfo";
import TemplateEditor from "../digitalforms/template-editor";
import CredentialPackagesList from "../credentialpackages/credentialpackages-list";
import AddCredentialPackage from "../credentialpackages/packages/add-packages";
import EditCredentialPackages from "../credentialpackages/packages/edit-packages";
import AddTask from "../tasks/modify/add-task";
import TaskList from "../tasks/task-list";
import ProvidersReport from "../reports/providersreports";
import PayerEnrollmentsReport from "../reports/payerenrollmentsreports";
import ProviderAffiliationsReport from "../reports/provideraffiliationreports";
import AllDocumentsReport from "../reports/allDocumentsreport";
import ExpiringDocumentsReport from "../reports/expiringdocumentsreport";
import ExpiredDocumentsReport from "../reports/expiriedDocumentsreport";
import AddSignatureRequest from "../digitalforms/add-signature-request";
import AddPayerEnrollment from "../payerenrollment/modify/add-payerenrollment";
import EditPayerEnrollment from "../payerenrollment/modify/edit-payerenrollment";
import AddWorkflowTasks from "../workflow/workflowtasks/add-workflowtask";
import EditWorkflowTasks from "../workflow/workflowtasks/edit-workflowtask";
import EditTask from "../tasks/modify/edit-task";
import PayersList from "../manage/payer/payer-list";
import AddPayer from "../manage/payer/modify/add-payer";
import EditPayer from "../manage/payer/modify/edit-payer";
import PayerGroupList from "../manage/payergroup/payergroup-list";
import AddPayerGroup from "../manage/payergroup/modify/add-payergroup";
import EditPayerGroup from "../manage/payergroup/modify/edit-payergroup";
import FacilityGroupList from "../manage/facilitygroup/facilitygroup-list";
import AddFacilityGroup from "../manage/facilitygroup/modify/add-facilitygroup";
import EditFacilityGroup from "../manage/facilitygroup/modify/edit-facilitygroup";
import WorkflowTemplateList from "../manage/workflowTemplate/workflowtemplate-list";
import EditLicenseType from "../manage/licensetypes/modify/edit-licensetype";
import AddLicenseType from "../manage/licensetypes/modify/add-licensetype";
import LicenseTypesList from "../manage/licensetypes/licensetypes-list";
import CategoryList from "../manage/category/category-list";
import AddCategory from "../manage/category/modify/add-category";
import EditCategory from "../manage/category/modify/edit-category";
import AddWorkFlowTemplate from "../manage/workflowTemplate/modify/add-workflowtemplate";
import EditWorkFlowTemplate from "../manage/workflowTemplate/modify/edit-workflowtemplate";
import CredentialTemplateList from "../manage/credentialtemplate/credentialtemplate-list";
import AddCredentialTemplate from "../manage/credentialtemplate/modify/add-credentialtemplate";
import EditCredentialTemplateDetails from "../manage/credentialtemplate/modify/edit-credentialtemplatedetails";
import EditDocument from "../document/modify/edit-document";
import Profile from "../profile";
import RolesAndPermissions from "../roles/roles-list";
import AddProviderEnrolledPayer from "../provider/modify/add-providerenrolledpayer";
import EditProviderEnrolledPayer from "../provider/modify/edit-providerenrolledpayer";
import AddAffiliation from "../affiliation/modify/add-affiliation";
import RoleDetails from "../roles/manage-roles/role-details";
import OrganizationSettings from "../organization";
import OrgBasicDetailsEdit from "../organization/org-basic-details-edit";
import ManageAdmin from "../manageadmin/manageadmin";
import AddInviteMember from "../manageadmin/modify/add-invitemember";
import EditActiveMember from "../manageadmin/modify/edit-activemembers";
import AffiliationStatusList from "../provider/providerDetails/affiliationstatus";
import ContractsTypeList from "../contracts/contracttype";
import PayerEnrollmentStatusList from "../payerenrollment/payerenrollmentstatus";
import CredentialPackageTypeList from "../credentialpackages/packagestype";
import CredentialPackageStatusList from "../credentialpackages/packageStatus";
import WorkFlowStatusList from "../workflow/workflowstatus";
import TasksTypeList from "../tasks/taskstype";
import TasksStatusList from "../tasks/tasksstatus";
import AddContractType from "../contracts/contracttype/modify/add-contracttype";
import EditContractType from "../contracts/contracttype/modify/edit-contracttype";
import AddPackageType from "../credentialpackages/packagestype/modify/add-packagetype";
import EditPackageType from "../credentialpackages/packagestype/modify/edit-packagetype";
import AddPackageStatus from "../credentialpackages/packageStatus/modify/add-packagestatus";
import EditPackageStatus from "../credentialpackages/packageStatus/modify/edit-packagestatus";
import AddWorkflowStatus from "../workflow/workflowstatus/modify/add-workflowstatus";
import EditWorkflowStatus from "../workflow/workflowstatus/modify/edit-workflowstatus";
import AddEnrollmentStatus from "../payerenrollment/payerenrollmentstatus/modify/add-enrollmentstatus";
import EditEnrollmentStatus from "../payerenrollment/payerenrollmentstatus/modify/edit-enrollmentstatus";
import AddTaskStatus from "../tasks/tasksstatus/modify/add-taskstatus";
import EditTaskStatus from "../tasks/tasksstatus/modify/edit-taskstatus";
import AddTaskType from "../tasks/taskstype/modify/add-tasktype";
import EditTaskType from "../tasks/taskstype/modify/edit-tasktype";
import AddAffiliationStatus from "../provider/modify/add-affiliationstatus";
import FacilityTypeList from "../facility/facilitytype-list";
import AddFacilityType from "../facility/modify/add-facilitytype";
import EditFacilityType from "../facility/modify/edit-facilitytype";
import ReadyFormTypeList from "../digitalforms/digitalformtypelist";
import AddReadyFormType from "../digitalforms/add-digitalformtype";
import EditReadyFormType from "../digitalforms/edit-digitalformtype";
import EditAffiliationStatus from "../provider/modify/edit-affiliationstatus";
import SpecialtyList from "../provider/providerDetails/specialtylist";
import EditSpecialty from "../provider/modify/edit-specialty";
import AddSpecialty from "../provider/modify/add-specialty";
import BoardNameList from "../provider/providerDetails/boardname";
import AddBoardName from "../provider/modify/add-boardname";
import EditBoardName from "../provider/modify/edit-boardname";
import AffiliationTypeList from "../provider/providerDetails/affiliationtype";
import AddAffiliationType from "../provider/modify/add-affiliationtype";
import EditAffiliationType from "../provider/modify/edit-affiliationtype";
import DigitalformRequestSignature from "../requests/signature-requests/digitalform-signature-request-wrapper";
import SignatureDetails from "../requests/signature-requests/signature-detail";
import { SigningDocument } from "../token-based/signing-document";
import SigningFailed from "../token-based/signature/signing-failed";
import SigningSuccess from "../token-based/signature/signing-success";
import ProviderListV3 from "../provider/providerlist-v3";
import AffiliationsListV2 from "../affiliation/affiliation-list-v2";
import PayerEnrollmentListV2 from "../payerenrollment/payerenrollment-list-v2";
import AdminRequests from "../requests/admin-requests";
import AddDocumentRequest from "../requests/document-requests/modify/add-document-request";
import SafeListV3 from "../safe/safe-list-v3";

export const InvalidPathRoute = () => {
  const location = useLocation();
  useEffect(() => {
    captureException(`Invalid path: ${location.pathname}`);
    console.error(`Invalid path: ${location.pathname}`);
  }, [location.pathname]);

  return <Navigate to={PATH_AUTH.login} replace />;
};

function Router() {
  return useRoutes([
    {
      path: "/",
      element: (
        <AuthGuard>
          <IncredableSpinner>
            <IncredableToast>
              <IncredableLayout />
            </IncredableToast>
          </IncredableSpinner>
        </AuthGuard>
      ),
      children: [
        { path: "/dashboard", element: <Dashboard />, index: true },
        { path: "/", element: <Dashboard />, index: true },
        {
          path: "/manageprovider",
          children: [
            { element: <ProviderListV3 />, index: true },
            {
              path: "add",
              element: <AddProvider />,
            },
            {
              path: ":providerId/edit",
              element: <EditProvider />,
            },
            {
              path: ":providerId",
              children: [
                {
                  element: (
                    <PermissionGaurd permissionKey="provider.view">
                      <ProviderDetails active="profile" />
                    </PermissionGaurd>
                  ),
                  index: true,
                },
                {
                  path: "affiliation",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="affiliation" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderAffiliation />,
                    },
                    {
                      path: ":affiliationId/edit",
                      element: <EditProviderAffiliation />,
                    },
                  ],
                },
                {
                  path: "dea",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="dea" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderDEA />,
                    },
                    {
                      path: ":deaId/edit",
                      element: <EditProviderDEA />,
                    },
                  ],
                },
                {
                  path: "education",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="education" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },

                    {
                      path: "add",
                      element: <EditProviderEducation />,
                    },
                    {
                      path: ":educationId/edit",
                      element: <EditProviderEducation />,
                    },
                  ],
                },
                {
                  path: "liabilityinsurance",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="liabilityinsurance" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderLiabilityInsurance />,
                    },
                    {
                      path: ":liabilityinsuranceId/edit",
                      element: <EditProviderLiabilityInsurance />,
                    },
                  ],
                },
                {
                  path: "license",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="license" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderLicense />,
                    },
                    {
                      path: ":licenseId/edit",
                      element: <EditProviderLicense />,
                    },
                  ],
                },
                {
                  path: "peerreference",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="peerreference" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderPeerReference />,
                    },
                    {
                      path: ":peerReferenceId/edit",
                      element: <EditProviderPeerReference />,
                    },
                  ],
                },
                {
                  path: "specialtyinfo",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="specialtyinfo" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderSpecialtyInfo />,
                    },
                    {
                      path: ":specialtyinfoId/edit",
                      element: <EditProviderSpecialtyInfo />,
                    },
                  ],
                },
                {
                  path: "thirdpartylogin",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="thirdpartylogin" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderThirdPartyLogin />,
                    },
                    {
                      path: ":thirdpartyloginId/edit",
                      element: <EditProviderThirdPartyLogin />,
                    },
                  ],
                },
                {
                  path: "workhistory",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="workhistory" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <EditProviderWorkHistory />,
                    },
                    {
                      path: ":workhistoryId/edit",
                      element: <EditProviderWorkHistory />,
                    },
                  ],
                },
                {
                  path: "enrolledpayer",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="enrolledpayer" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "add",
                      element: <AddProviderEnrolledPayer />,
                    },
                    {
                      path: ":enrolledPayerId/edit",
                      element: <EditProviderEnrolledPayer />,
                    },
                  ],
                },
                {
                  path: "personalinfo",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="personalinfo" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "edit",
                      element: <EditProviderPersonalInfo />,
                    },
                  ],
                },
                {
                  path: "professionalinfo",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="professionalinfo" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                    {
                      path: "edit",
                      element: <EditProviderProfessionalInfo />,
                    },
                  ],
                },
                {
                  path: "profile",
                  children: [
                    {
                      element: (
                        <PermissionGaurd permissionKey="provider.view">
                          <ProviderDetails active="profile" />
                        </PermissionGaurd>
                      ),
                      index: true,
                    },
                  ],
                },
                {
                  path: "editpersonalinfo",
                  element: <PersonalInfo />,
                },
                {
                  path: "editprofessionalinfo",
                  element: <ProfessionalInfo />,
                },
              ],
            },
          ],
        },
        {
          path: "/affiliationtype",
          children: [
            { element: <AffiliationTypeList />, index: true },
            { path: "add", element: <AddAffiliationType /> },
            {
              path: ":affiliationtypeId/edit",
              element: <EditAffiliationType />,
            },
          ],
        },
        {
          path: "/affiliationstatus",
          children: [
            { element: <AffiliationStatusList />, index: true },
            { path: "add", element: <AddAffiliationStatus /> },
            {
              path: ":affiliationstatusId/edit",
              element: <EditAffiliationStatus />,
            },
          ],
        },
        {
          path: "/specialty",
          children: [
            { element: <SpecialtyList />, index: true },
            { path: "add", element: <AddSpecialty /> },
            { path: ":specialtyId/edit", element: <EditSpecialty /> },
          ],
        },
        {
          path: "/boardname",
          children: [
            { element: <BoardNameList />, index: true },
            { path: "add", element: <AddBoardName /> },
            { path: ":boardnameId/edit", element: <EditBoardName /> },
          ],
        },
        {
          path: "/facilitytype",
          children: [
            { element: <FacilityTypeList />, index: true },
            { path: "add", element: <AddFacilityType /> },
            { path: ":facilitytypeId/edit", element: <EditFacilityType /> },
          ],
        },
        {
          path: "/readyformtype",
          children: [
            { element: <ReadyFormTypeList />, index: true },
            { path: "add", element: <AddReadyFormType /> },
            { path: ":readyformtypeId/edit", element: <EditReadyFormType /> },
          ],
        },
        { path: "/dashboard", element: <Dashboard /> },
        { path: "/affiliationtype", element: <EditAffiliationType /> },
        {
          path: "/managefacilities",
          children: [
            { element: <FacilityList />, index: true },
            {
              path: "add",
              element: <AddFacility />,
            },
            {
              path: ":facilityId/edit",
              element: <EditFacility />,
            },
          ],
        },
        {
          path: "/contracttype",
          children: [
            { element: <ContractsTypeList />, index: true },
            {
              path: "add",
              element: <AddContractType />,
            },
            {
              path: ":typeId/edit",
              element: <EditContractType />,
            },
          ],
        },
        {
          path: "requests",
          children: [
            { element: <AdminRequests />, index: true },
            {
              path: "add",
              element: <AddDocumentRequest />,
            },
          ],
        },
        {
          path: "/contract",
          children: [
            { element: <ContractsList />, index: true },
            {
              path: "add",
              element: <AddContract />,
            },
            {
              path: ":contractId/edit",
              element: <EditContract />,
            },
          ],
        },
        {
          path: "/providerroatser",
          element: <ProvidersReport />,
        },
        {
          path: "/enrollmentreport",
          element: <PayerEnrollmentsReport />,
        },
        {
          path: "/provideraffiliation",
          element: <ProviderAffiliationsReport />,
        },
        {
          path: "/alldocuments",
          element: <AllDocumentsReport />,
        },
        {
          path: "/expiringdocuments",
          element: <ExpiringDocumentsReport />,
        },
        {
          path: "/expireddocuments",
          element: <ExpiredDocumentsReport />,
        },
        {
          path: "/payerenrollmentstatus",

          children: [
            { element: <PayerEnrollmentStatusList />, index: true },
            {
              path: "add",
              element: <AddEnrollmentStatus />,
            },
            {
              path: ":enrollmentStatusId/edit",
              element: <EditEnrollmentStatus />,
            },
          ],
        },
        {
          path: "/payerenrollment",
          children: [
            {
              element: <PayerEnrollmentListV2 />,
              index: true,
            },
            {
              path: "add",
              element: <AddPayerEnrollment />,
            },
          ],
        },
        {
          path: "/payer/:payerId/payerenrollment/:enrollmentId/edit",
          element: <EditPayerEnrollment />,
        },
        {
          path: "/payers",
          children: [
            {
              element: <PayersList />,
              index: true,
            },
            {
              path: "add",
              element: <AddPayer />,
            },
            {
              path: ":payerId/edit",
              element: <EditPayer />,
            },
          ],
        },
        {
          path: "/payergroup",
          children: [
            {
              element: <PayerGroupList />,
              index: true,
            },
            {
              path: "add",
              element: <AddPayerGroup />,
            },
            {
              path: ":payergroupId/edit",
              element: <EditPayerGroup />,
            },
          ],
        },
        {
          path: "/facilitygroup",
          children: [
            {
              element: <FacilityGroupList />,
              index: true,
            },
            {
              path: "add",
              element: <AddFacilityGroup />,
            },
            {
              path: ":facilitygroupId/edit",
              element: <EditFacilityGroup />,
            },
          ],
        },
        {
          path: "/templatetype",
          children: [
            { element: <TasksTypeList />, index: true },
            {
              path: "add",
              element: <AddTaskType />,
            },
            {
              path: ":typeId/edit",
              element: <EditTaskType />,
            },
          ],
        },
        {
          path: "/templatestatus",
          children: [
            { element: <TasksStatusList />, index: true },
            {
              path: "add",
              element: <AddTaskStatus />,
            },
            {
              path: ":statusId/edit",
              element: <EditTaskStatus />,
            },
          ],
        },
        {
          path: "/workflowtemplate",
          children: [
            {
              element: <WorkflowTemplateList />,
              index: true,
            },
            {
              path: "add",
              element: <AddWorkFlowTemplate />,
            },
            {
              path: ":templateId/edit",
              element: <EditWorkFlowTemplate />,
            },
          ],
        },
        {
          path: "/licensetypes",
          children: [
            {
              element: <LicenseTypesList />,
              index: true,
            },
            {
              path: "add",
              element: <AddLicenseType />,
            },
            {
              path: ":licencetypeId/edit",
              element: <EditLicenseType />,
            },
          ],
        },
        {
          path: "/categories",
          children: [
            {
              element: <CategoryList />,
              index: true,
            },
            {
              path: "add",
              element: <AddCategory />,
            },
            {
              path: ":categoryId/edit",
              element: <EditCategory />,
            },
          ],
        },
        {
          path: "/credentialtemplates",
          children: [
            {
              element: <CredentialTemplateList />,
              index: true,
            },
            {
              path: "add",
              element: <AddCredentialTemplate />,
            },
            {
              path: ":templateId",
              element: <EditCredentialTemplateDetails />,
            },
          ],
        },
        {
          path: "/safe",
          children: [
            { element: <SafeListV3 />, index: true },
            {
              path: "addnew",
              element: <AddDocument />,
            },
            {
              path: ":providerId/documents/:documentId/edit",
              element: <EditDocument />,
            },
            {
              path: ":providerId/documents/uploadmultipledocument",
              element: <UploadMultipleDocuments />,
            },
          ],
        },
        {
          path: "/workflowstatus",
          children: [
            { element: <WorkFlowStatusList />, index: true },
            {
              path: "add",
              element: <AddWorkflowStatus />,
            },
            {
              path: ":workflowStatusId/edit",
              element: <EditWorkflowStatus />,
            },
          ],
        },
        {
          path: "workflows",
          children: [
            {
              element: <WorkFlowList />,
              index: true,
            },
            {
              path: "add",
              element: <AddWorkflow />,
            },
            {
              path: ":workflowId",
              element: <EditWorkflow />,
            },
            {
              path: "/workflows/:workflowId/addtask",
              element: <AddWorkflowTasks />,
            },
            {
              path: "/workflows/:workflowId/task/:taskId",
              element: <EditWorkflowTasks />,
            },
          ],
        },
        {
          path: "/readyforms",
          children: [
            { element: <ReadyFormsList />, index: true },
            { path: "add", element: <AddDigitalform /> },
            { path: ":digitalformId", element: <EditDigitalform /> },
            { path: ":digitalformId/editor", element: <TemplateEditor /> },
            {
              path: ":digitalformId/requestsignature",
              element: <DigitalformRequestSignature />,
            },
            {
              path: ":digitalformId/signaturerequest",
              element: <AddSignatureRequest />,
            },
          ],
        },
        {
          path: "/taskstype",
          children: [
            { element: <TasksTypeList />, index: true },
            {
              path: "add",
              element: <AddTaskType />,
            },
            {
              path: ":typeId/edit",
              element: <EditTaskType />,
            },
          ],
        },
        {
          path: "/taskstatus",
          children: [
            { element: <TasksStatusList />, index: true },
            {
              path: "add",
              element: <AddTaskStatus />,
            },
            {
              path: ":statusId/edit",
              element: <EditTaskStatus />,
            },
          ],
        },
        {
          path: "tasks",
          children: [
            {
              element: <TaskList />,
              index: true,
            },
            {
              path: "add",
              element: <AddTask />,
            },
            {
              path: ":taskId",
              element: <EditTask />,
            },
          ],
        },
        {
          path: "packagetype",
          children: [
            { element: <CredentialPackageTypeList />, index: true },
            {
              path: "add",
              element: <AddPackageType />,
            },
            {
              path: ":packageTypeId/edit",
              element: <EditPackageType />,
            },
          ],
        },
        {
          path: "packagestatus",
          children: [
            { element: <CredentialPackageStatusList />, index: true },
            {
              path: "add",
              element: <AddPackageStatus />,
            },
            {
              path: ":packageStatusId/edit",
              element: <EditPackageStatus />,
            },
          ],
        },
        {
          path: "credentialpackages",
          children: [
            {
              element: <CredentialPackagesList />,
              index: true,
            },
            {
              path: "add",
              element: <AddCredentialPackage />,
            },
            {
              path: ":id",
              element: <EditCredentialPackages />,
            },
          ],
        },
        {
          path: "privileges",
          children: [
            {
              element: <AffiliationsListV2 />,
              index: true,
            },
            {
              path: "addaffiliation",
              element: <AddAffiliation />,
            },
            {
              path: "editaffiliation/:affiliationId",
              element: <AddAffiliation />,
            },
          ],
        },
        {
          path: "/manageadmin",
          children: [
            {
              element: <ManageAdmin />,
              index: true,
            },
            {
              path: "add",
              element: <AddInviteMember />,
            },
            {
              path: ":activeMemberId/edit",
              element: <EditActiveMember />,
            },
          ],
        },
        {
          path: "/requests",
          children: [
            {
              element: <AdminRequests />,
              index: true,
            },
            {
              path: ":signatureId",
              element: <SignatureDetails />,
            },
          ],
        },
        {
          path: "org",
          children: [
            {
              element: <OrganizationSettings />,
              index: true,
            },
            {
              path: "details/edit",
              element: <OrgBasicDetailsEdit />,
            },
            // {
            //   path: "settings/edit",
            //   element: <OrgBasicDetailsEdit />,
            // },
          ],
        },
        {
          path: "settings/profile",
          children: [
            {
              element: <Profile />,
              index: true,
            },
          ],
        },
        {
          path: "settings/roles",
          children: [
            {
              element: <RolesAndPermissions />,
              index: true,
            },
            {
              path: ":id",
              element: <RoleDetails />,
            },
          ],
        },
      ],
    },
    {
      path: PATH_AUTH.login,
      element: <Login />,
    },
    {
      path: "signing",
      children: [
        { element: <SigningDocument />, index: true },
        { path: "error", element: <SigningFailed /> },
        { path: "success", element: <SigningSuccess /> },
      ],
    },
  ]);
}

export default Router;
