import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  useGetAllFacilityTypesQuery,
  useGetFacilitiesQuery,
} from "../redux/api/facility/facility";

export default function useFacilityFilter() {
  const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
  const [selectedFiltervalue, setSelectedFiltervalue] = useState<
    string | undefined
  >(undefined);
  const { data: facilityTypes = [], isLoading: facilityTypesLoading } =
    useGetAllFacilityTypesQuery();
  const {
    data: facilities = [],
    refetch,
    isLoading: facilitiesLoading,
    isFetching,
  } = useGetFacilitiesQuery();

  const filteredFacilities = useMemo(() => {
    if (!!selectedFiltervalue) {
      if (selectedFilterkey === "name")
        return facilities?.filter((facility) =>
          facility?.name
            ?.toLowerCase()
            .includes(selectedFiltervalue?.toLowerCase())
        );
      else if (selectedFilterkey === "facilitytype")
        return facilities?.filter(
          (facility) =>
            facility?.facilityType?.id + "" === selectedFiltervalue + ""
        );
    }
    return facilities;
  }, [selectedFiltervalue, facilities]);

  useEffect(() => {
    setSelectedFiltervalue(undefined);
  }, [selectedFilterkey]);

  const TableFilters = (
    <Space size="small">
      {selectedFilterkey === "name" && (
        <Input
          onChange={(event) => {
            setSelectedFiltervalue(event?.target?.value);
          }}
          value={selectedFiltervalue}
          style={{
            width: "320px",
          }}
          placeholder="Find Name"
          prefix={<SearchOutlined />}
        />
      )}
      {selectedFilterkey === "facilitytype" && (
        <Select
          allowClear={true}
          showSearch={true}
          optionFilterProp="label"
          value={selectedFiltervalue}
          placeholder="Select Facility Type"
          onChange={(value) => {
            setSelectedFiltervalue(value);
          }}
          style={{ width: "320px" }}
          options={facilityTypes?.map((facilityType) => ({
            label: facilityType?.name,
            searchValue: facilityType?.name,
            value: facilityType?.id + "",
          }))}
        />
      )}
      <Select
        value={selectedFilterkey}
        onChange={(value) => {
          setSelectedFilterKey(value);
        }}
        style={{ width: "140px" }}
        options={[
          { value: "name", label: "Name" },
          {
            value: "facilitytype",
            label: "Facility Type",
          },
        ]}
      />
    </Space>
  );

  return {
    TableFilters,
    filteredFacilities,
    UseQueryHookResult: {
      refetch,
      isLoading: facilitiesLoading,
      isFetching,
    },
  };
}
