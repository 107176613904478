import { Descriptions } from "antd";
import React from "react";
import IncredableButton from "../button";
import { EditOutlined } from "@ant-design/icons";

interface ProviderCardProps {
  title: string;
  extraButton?: {
    text: string;
    icon?: string;
    onClick?: () => void;
  };
  data: {
    label: string;
    value: string | boolean | Date | number | { id: string; name: string };
  }[];
}

const ProviderCard: React.FC<ProviderCardProps> = ({
  title,
  extraButton,
  data,
}) => {
  return (
    <Descriptions
      style={{
        padding: "24px",
        backgroundColor: "white",
        borderRadius: "8px",
        border: "1px solid #E5E7EB",
      }}
      title={title}
      extra={
        !!extraButton && (
          <IncredableButton
            icon={<EditOutlined />}
            onClick={extraButton.onClick}
            type="primary"
          >
            {extraButton.text}
          </IncredableButton>
        )
      }
      bordered
      layout="vertical"
      items={data?.map((item) => {
        return {
          key: item.label,
          label: item.label,
          children: (
            <span>
              {typeof item.value === "string" ||
              typeof item.value === "boolean" ||
              typeof item.value === "number"
                ? item.value
                : "-"}
            </span>
          ),
        };
      })}
    />
  );
};

export default ProviderCard;
