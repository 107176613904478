import { useContext, useState } from "react";
import { Flex, Layout, Space, Table, theme, Typography } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import TableTitle from "../components/incredable-table/table-title";
import { TablePreferenceModal } from "../common/TablePreferenceModal/";
import { Facility } from "../redux/api/facility/types";
import useFacilityFilter from "../table-filters/facility-filters";
import IncredableButton from "../components/button";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useDeleteFacilityMutation } from "../redux/api/facility/facility";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";

export const facilityColumns = [
  {
    title: () => <Typography.Text>Name</Typography.Text>,
    key: "name",
    ellipsis: true,
    render: (facility: Facility) => (
      <NavLink to={`${facility.id}/edit`}>
        {facility?.name}
      </NavLink>
    ),
    sorter: (a: Facility, b: Facility) =>
      a?.name?.toLowerCase()?.localeCompare(b?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>NPI Number</Typography.Text>,
    dataIndex: "npiNumber",
    key: "npiNumber",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: Facility, b: Facility) =>
      a?.npiNumber?.toLowerCase()?.localeCompare(b?.npiNumber?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Facility Type</Typography.Text>,
    dataIndex: ["facilityType", "name"],
    key: "facilityType",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: Facility, b: Facility) =>
      a?.facilityType?.name?.toLowerCase()?.localeCompare(b?.facilityType?.name?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Tax ID</Typography.Text>,
    dataIndex: "taxId",
    key: "taxId",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>CLIA ID</Typography.Text>,
    dataIndex: "cliaId",
    key: "cliaId",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Immunization ID</Typography.Text>,
    dataIndex: "immunizationId",
    key: "immunizationId",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Taxonomy Code</Typography.Text>,
    dataIndex: "taxonomyCode",
    key: "taxonomyCode",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: Facility, b: Facility) =>
      a?.taxonomyCode?.toLowerCase()?.localeCompare(b?.taxonomyCode?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>Phone Number</Typography.Text>,
    dataIndex: "phoneNumber",
    key: "phoneNumber",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Email</Typography.Text>,
    dataIndex: "email",
    key: "email",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>State</Typography.Text>,
    dataIndex: ["facilityAddress", "state"],
    key: "state",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
    sorter: (a: Facility, b: Facility) =>
      a?.facilityAddress?.state?.toLowerCase()?.localeCompare(b?.facilityAddress?.state?.toLowerCase()),
  },
];

export default function FacilityList() {
  const [selectedRow, setSelectedRow] = useState<Facility>();
  const { TableFilters, filteredFacilities, UseQueryHookResult } =
    useFacilityFilter();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const filteredColumns = [
    "name",
    "npiNumber",
    "facilityType",
    "phoneNumber",
    "email",
    "state"
  ];

  const [selectedColumns, setSelectedColumns] =
    useState<string[]>(filteredColumns);
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    facilityColumns.map((col) => col.key as string)
  );

  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  // https://codesandbox.io/p/sandbox/000vqw38rl
  const selectRow = (record: Facility) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };
  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => facilityColumns.find((col) => col.key === colKey)!);

  const [deleteFacility] = useDeleteFacilityMutation();

  return (
    <Layout style={{ margin: "20px 30px 0 30px" }}>
      <Space size="large" direction="vertical">
        <Table
          style={{
            boxShadow: "0px 10px 25px 0px #5911920D",
            borderRadius: "16px",
            border: "1px solid #E5E7EB",
            overflow: "hidden",
          }}
          size="small"
          columns={visibleColumns}
          loading={
            UseQueryHookResult.isLoading || UseQueryHookResult.isFetching
          }
          rowKey="id"
          dataSource={!!filteredFacilities ? [...filteredFacilities] : []}
          onRow={(record) => ({
            onClick: () => {
              selectRow(record);
            },
          })}
          rowSelection={{
            type: "radio",
            selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
            onChange: (selectedRowKeys, selectedRows, info) => {
              if (!!selectedRows && !!selectedRows?.at(0))
                setSelectedRow(selectedRows?.at(0));
            },
            columnWidth: 60,
          }}
          pagination={{
            position: ["bottomRight"],
            style: { backgroundColor: "white", padding: "10px", margin: "0" },
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            total: filteredFacilities?.length,
            showTotal: (total) => `Total ${total} items`,
          }}
          title={() => (
            <Space direction="vertical" style={{ display: "flex" }}>
              <Flex
                align="start"
                justify={"space-between"}
                style={{ margin: "16px 10px 0 10px" }}
              >
                <Space size="middle">
                  <Typography.Title
                    style={{ paddingTop: "0", marginTop: "0" }}
                    level={4}
                  >
                    {`Facilities (${filteredFacilities?.length})`}
                  </Typography.Title>
                </Space>
                <Space size="middle">
                  <IncredableButton
                    onClick={() => navigate("add")}
                    type="primary"
                    icon={<PlusOutlined />}
                    size="large"
                  >
                    Add Facility
                  </IncredableButton>
                </Space>
              </Flex>
              <TableTitle
                filter={TableFilters}
                onReloadClick={UseQueryHookResult.refetch}
                actionDropDownProps={{
                  disabled: !selectedRow,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: async (e) => {
                      if (e?.key === "edit") {
                        navigate(`${selectedRow?.id}/edit`);
                      }
                      if (e?.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
              />
            </Space>
          )}
        />
        <TablePreferenceModal
          visible={isModalVisible}
          onCancel={handleCloseModal}
          onSave={handleSavePreferences}
          availableColumns={facilityColumns.map((col) => col.key as string)}
          selectedColumns={selectedColumns}
          columnOrderState={visibleColumnOrder}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRow?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              await deleteFacility({
                facilityId: selectedRow?.id,
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Facility deleted successfully`,
                placement: "topRight",
              });
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Facility"
          content={
            <Typography.Text>
              Facility Name:{" "}
              <Typography.Text>{selectedRow?.name}</Typography.Text>
            </Typography.Text>
          }
          description="Are you sure you want to delete this facility?"
        />
      </Space>
    </Layout>
  );
}
