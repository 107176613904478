import { WidgetConfig } from "../types";
import { BarChart } from "@cloudscape-design/components";

export const usageByFacility: WidgetConfig = {
  title: "Payer enrollments by status",
  description: "An overview of the payer enrollments breakdown by status.",
  header: UsageByFacilityHeader,
  content: UsageByFacilityContent,
};

function UsageByFacilityHeader() {
  return (
    <div
      style={{
        borderRadius: "4px",
        padding: "0px 10px 0 18px",
      }}
    >
      <h3>{usageByFacility.title}</h3>
      <p>{usageByFacility.description}</p>
    </div>
  );
}

function UsageByFacilityContent() {
  return (
    <div style={{ padding: "24px" }}>
      <BarChart
        series={[
          {
            title: "Payers count",
            type: "bar",
            data: [
              { x: "one", y: 34503 },
              { x: "two", y: 25832 },
              { x: "three", y: 4012 },
              { x: "four", y: 5602 },
              { x: "five", y: 17839 },
            ],
            color: "#2B6BE6",
          },
        ]}
        ariaLabel="Single data series line chart"
        height={280}
        xTitle="Enrollment Status"
        yTitle="Enrollments count"
        hideFilter
        hideLegend
      />
    </div>
  );
}
