import { Space, Table, Typography } from "antd";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableTitle from "../../components/incredable-table/table-title";
import {
  CalendarOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import { ProviderDeaType } from "../../redux/api/provider/types";
import {
  useDeleteProviderDeaMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableButton from "../../components/button";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";

export const deaColumns = [
  {
    title: () => <Typography.Text>Type</Typography.Text>,
    key: "type",
    width: "120px",
    ellipsis: true,
    render: (dea: ProviderDeaType) => (
      <Typography.Link href="#" >
        {dea?.type}
      </Typography.Link>
    ),
    sorter: (a: ProviderDeaType, b: ProviderDeaType) =>
      a?.type?.toLowerCase()?.localeCompare(b?.type?.toLowerCase()),
  },
  {
    title: () => <Typography.Text>DEA Number</Typography.Text>,
    dataIndex: "deaNumber",
    key: "deaNumber",
    ellipsis: true,
    render: (text: string) => (
      <Typography.Text >{text || "-"}</Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>Start Date</Typography.Text>,
    dataIndex: "startDate",
    key: "startDate",
    ellipsis: true,
    render: (text: string | null) => (
      <Typography.Text>
        {text ? (
          <Space>
            {" "}
            <CalendarOutlined /> {text}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: ProviderDeaType, b: ProviderDeaType) =>
      new Date(a?.startDate).getTime() - new Date(b?.startDate).getTime()
  },
  {
    title: () => <Typography.Text>End Date</Typography.Text>,
    dataIndex: "endDate",
    key: "endDate",
    ellipsis: true,
    render: (text: string | null) => (
      <Typography.Text>
        {text ? (
          <Space>
            {" "}
            <CalendarOutlined /> {text}
          </Space>
        ) : (
          "-"
        )}
      </Typography.Text>
    ),
    sorter: (a: ProviderDeaType, b: ProviderDeaType) =>
      new Date(a?.endDate).getTime() - new Date(b?.endDate).getTime()
  },
  {
    title: () => <Typography.Text>Status</Typography.Text>,
    dataIndex: "status",
    key: "status",
    ellipsis: true,
    render: (text: string) => (
      <Typography.Text>{text || "-"}</Typography.Text>
    ),
    sorter: (a: ProviderDeaType, b: ProviderDeaType) =>
      a?.status?.toLowerCase()?.localeCompare(b?.status?.toLowerCase()),

  },
  {
    title: () => <Typography.Text>State</Typography.Text>,
    dataIndex: "state",
    key: "state",
    ellipsis: true,
    render: (text: string) => (
      <Typography.Text>{text || "-"}</Typography.Text>
    ),
    sorter: (a: ProviderDeaType, b: ProviderDeaType) =>
      a?.state?.toLowerCase()?.localeCompare(b?.state?.toLowerCase()),

  },
];

export default function DEA() {
  const { providerId } = useParams();
  const [selectedRow, setSelectedRow] = useState<ProviderDeaType>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    deaColumns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    deaColumns.map((col) => col.key as string)
  );

  const {
    data: provider,
    isLoading,
    isFetching,
    refetch,
  } = useGetProviderQuery(
    !!providerId
      ? {
        providerId: providerId,
      }
      : skipToken
  );
  const navigate = useNavigate();

  const selectRow = (record: ProviderDeaType) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => deaColumns.find((col) => col.key === colKey)!);

  const [deleteDea] = useDeleteProviderDeaMutation();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  return (
    <>
      <Table
        style={{
          boxShadow: "0px 10px 25px 0px #5911920D",
          borderRadius: "16px",
          border: "1px solid #E5E7EB",
          overflow: "hidden",
        }}
        size="small"
        columns={visibleColumns}
        loading={isLoading || isFetching}
        rowKey="id"
        dataSource={provider?.providerDeas}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        rowSelection={{
          type: "radio",
          selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
          onChange: (selectedRowKeys, selectedRows, info) => {
            if (!!selectedRows && !!selectedRows?.at(0))
              setSelectedRow(selectedRows?.at(0));
            navigate("");
          },
          columnWidth: 60,
        }}
        pagination={{
          position: ["bottomRight"],
          style: { backgroundColor: "white", padding: "10px", margin: "0" },
          pageSizeOptions: [10, 25, 50],
          showSizeChanger: true,
          total: provider?.providerDeas?.length,
          showTotal: (total) => `Total ${total} items`,
        }}
        title={() => (
          <Space
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontSize: "22px", fontWeight: "700" }}>
              {`DEA (${provider?.providerDeas?.length})`}
            </span>
            <Space>
              <IncredableButton
                onClick={() => navigate(`/manageprovider/${providerId}/dea/add`)}
                type="primary"
                icon={<PlusOutlined />}
                size="middle"
                style={{
                  backgroundColor: "#598BEB",
                  border: "2px solid #598BEB",
                  boxShadow: "none",
                }}
              >
                Add DEA
              </IncredableButton>
              <TableTitle
                filter={[]}
                actionDropDownProps={{
                  disabled: !selectedRow?.id,
                  menu: {
                    items: [
                      {
                        label: "Edit",
                        key: "edit",
                        icon: <EditOutlined />,
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                      },
                    ],
                    onClick: async (e) => {
                      if (e?.key === "edit") {
                        navigate(`${selectedRow?.id}/edit`);
                      }
                      if (e?.key === "delete") {
                        handleOpenDeleteModal();
                      }
                    },
                  },
                }}
                onSettingsClick={handleOpenModal}
                onReloadClick={refetch}
              />
            </Space>
          </Space>
        )}
        scroll={{ y: 400, x: "100vw" }}
      />
      <TablePreferenceModal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        onSave={handleSavePreferences}
        availableColumns={deaColumns.map((col) => col.key as string)}
        selectedColumns={selectedColumns}
        columnOrderState={visibleColumnOrder}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRow?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteDea({
              providerId: providerId,
              deaId: selectedRow?.id + "",
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `DEA deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete DEA"
        content={
          <Typography.Text>
            DEA Number:{" "}
            <Typography.Text strong>{selectedRow?.deaNumber}</Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this DEA?"
      />
    </>
  );
}
