import { useContext, useState } from "react";
import { Flex, Layout, Space, Table, Typography } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import IncredableDateFormat from "../../common/incredable-date-format";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import IncredableButton from "../../components/button";
import TableTitle from "../../components/incredable-table/table-title";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { WorkFlowTask } from "../../redux/api/workflow/types";
import { useGetWorkflowQuery } from "../../redux/api/workflow/workflow";
import { skipToken } from "@reduxjs/toolkit/query";
import { useDeleteTaskMutation } from "../../redux/api/task";

export const columns = [
  {
    title: () => <Typography.Text>Task Id</Typography.Text>,
    dataIndex: "id",
    key: "id",
    width: "120px",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Task Type</Typography.Text>,
    dataIndex: ["taskType", "name"],
    key: "taskType",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Name</Typography.Text>,
    dataIndex: "name",
    key: "name",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Task Status</Typography.Text>,
    dataIndex: ["taskStatus", "name"],
    key: "taskStatus",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Assigned To</Typography.Text>,
    dataIndex: ["admin"],
    key: "admin",
    ellipsis: true,
    render: (admin: { firstName: string; lastName: string }) => (
      <Typography.Text>
        {admin?.firstName && admin?.lastName
          ? `${admin.firstName} ${admin.lastName}`
          : "-"}
      </Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>Provider</Typography.Text>,
    dataIndex: ["provider"],
    key: "provider",
    ellipsis: true,
    render: (provider: { firstName: string; lastName: string }) => (
      <Typography.Text>
        {provider?.firstName && provider?.lastName
          ? `${provider.firstName} ${provider.lastName}`
          : "-"}
      </Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>Facility</Typography.Text>,
    dataIndex: ["facility", "name"],
    key: "facility",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Start Date</Typography.Text>,
    dataIndex: "startDate",
    key: "startDate",
    ellipsis: true,
    render: (startDate: string) => (
      <Typography.Text strong>
        {IncredableDateFormat(startDate) ?? "-"}
      </Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>Due Date</Typography.Text>,
    dataIndex: "dueDate",
    key: "dueDate",
    ellipsis: true,
    render: (dueDate: string) => (
      <Typography.Text strong>
        {IncredableDateFormat(dueDate) ?? "-"}
      </Typography.Text>
    ),
  },
  {
    title: () => <Typography.Text>Created Date</Typography.Text>,
    dataIndex: "createdDate",
    key: "createdDate",
    ellipsis: true,
    render: (text: string) => (
      <Typography.Text strong>
        {IncredableDateFormat(text) ?? "-"}
      </Typography.Text>
    ),
  },
];

export default function TasksList() {
  const [selectedRow, setSelectedRow] = useState<WorkFlowTask>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState<string[]>(
    columns.map((col) => col.key as string)
  );
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { workflowId } = useParams();
  const { data: workflow, isLoading } = useGetWorkflowQuery(
    !!workflowId ? { id: workflowId } : skipToken
  );
  const [deleteTask] = useDeleteTaskMutation();

  const selectRow = (record: WorkFlowTask) => {
    if (selectedRow?.id + "" === record?.id + "") {
      setSelectedRow(undefined);
    } else {
      setSelectedRow(record);
    }
  };

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    handleCloseModal();
  };

  const visibleColumns = visibleColumnOrder
    .filter((colKey) => selectedColumns.includes(colKey))
    .map((colKey) => columns.find((col) => col.key === colKey)!);

  return (
    <Layout>
      <Table
        size="small"
        columns={visibleColumns}
        loading={isLoading}
        rowKey="id"
        dataSource={workflow?.tasks}
        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
        rowSelection={{
          type: "radio",
          selectedRowKeys: !!selectedRow?.id ? [selectedRow?.id] : [],
          onChange: (selectedRowKeys, selectedRows, info) => {
            if (!!selectedRows && !!selectedRows?.at(0))
              setSelectedRow(selectedRows?.at(0));
          },
          columnWidth: 60,
        }}
        pagination={{
          position: ["bottomRight"],
          style: { backgroundColor: "white", padding: "10px", margin: "0" },
          pageSizeOptions: [10, 25, 50, 100, 300],
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
        scroll={{ y: "50vh", x: "100vw" }}
        title={() => (
          <Space direction="vertical" style={{ display: "flex" }}>
            <Flex
              align="start"
              justify={"space-between"}
              style={{ margin: "16px 10px 0 10px" }}
            >
              <Space size="middle">
                <Typography.Title
                  style={{ paddingTop: "0", marginTop: "0" }}
                  level={4}
                >
                  {`Tasks (${workflow?.tasks?.length ?? ""})`}
                </Typography.Title>
              </Space>
              <Space size="middle">
                <IncredableButton
                  onClick={() => navigate("addtask")}
                  type="primary"
                  icon={<PlusOutlined />}
                  size="large"
                >
                  Add Task
                </IncredableButton>
              </Space>
            </Flex>
            <TableTitle
              onReloadClick={() => ""}
              actionDropDownProps={{
                disabled: !selectedRow,
                menu: {
                  items: [
                    {
                      label: "Edit",
                      key: "edit",
                      icon: <EditOutlined />,
                    },
                    {
                      label: "Delete",
                      key: "delete",
                      icon: <DeleteOutlined />,
                      danger: true,
                    },
                  ],
                  onClick: (e) => {
                    if (e?.key === "edit") {
                      navigate(`task/${selectedRow?.id}`);
                    }
                    if (e?.key === "delete") {
                      handleOpenDeleteModal();
                    }
                  },
                },
              }}
              onSettingsClick={handleOpenModal}
            />
          </Space>
        )}
      />
      <TablePreferenceModal
        visible={isModalVisible}
        onCancel={handleCloseModal}
        onSave={handleSavePreferences}
        availableColumns={columns.map((col) => col.key as string)}
        selectedColumns={selectedColumns}
        columnOrderState={visibleColumnOrder}
      />

      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedRow?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteTask({
              id: String(selectedRow?.id),
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `workflowTask deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete WorkflowTask "
        content={
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text>WorkflowTask Name</Typography.Text>
              <Typography.Text strong style={{ marginTop: "8px" }}>
                {selectedRow?.name}
              </Typography.Text>
            </div>
          </>
        }
        description="Are you sure you want to delete this workflowTask?"
      />
    </Layout>
  );
}
