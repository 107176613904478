import { Card, Col, Row, Space, Typography, Form, Radio } from "antd";
import IncredableContent from "../components/incredable-content";
import IncredableButton from "../components/button";
import { useState, useEffect, useContext } from "react";
import {
  useGetOrganizationDetailsQuery,
  useUpdateOrganizationSettingsMutation,
} from "../redux/api/organization/organization";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { OrganizationSettingsType } from "../redux/api/organization/types";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

export default function OrganizationSettings() {
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const navigate = useNavigate();

  const { data: organization } = useGetOrganizationDetailsQuery();

  const [orgSettings, setOrgSettings] = useState<OrganizationSettingsType>();

  const [updateOrganizationSettings] = useUpdateOrganizationSettingsMutation();

  useEffect(() => {
    setOrgSettings(organization?.settings);
  }, [organization]);

  return (
    <>
      <IncredableContent style={{ margin: "30px 20px 0", padding: "0" }}>
        <Card
          bordered={false}
          title="Basic Details"
          extra={
            <IncredableButton
              type="primary"
              onClick={() => navigate("/org/details/edit")}
            >
              Edit
            </IncredableButton>
          }
        >
          <Row>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Space direction="vertical">
                  <Text strong>Name</Text>
                  <Text>{organization?.name ?? "--"}</Text>
                </Space>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Space direction="vertical">
                  <Text strong>Phone Number</Text>
                  <Text>{organization?.phone ?? "--"}</Text>
                </Space>
              </Space>
            </Col>
            <Col span={24} style={{ margin: "10px 0" }}>
              <Space direction="vertical" size="large">
                <Text strong>Organization Address</Text>
              </Space>
            </Col>
            <Col span={12}>
              <Space direction="vertical" size="large">
                <Space direction="vertical">
                  <Text strong>Address Line 1</Text>
                  <Text>
                    {organization?.organizationAddress?.addressLane1 ?? "--"}
                  </Text>
                </Space>
              </Space>
            </Col>
            <Col>
              <Space direction="vertical">
                <Text strong>Address Line 2</Text>
                <Text>
                  {organization?.organizationAddress?.addressLane2 ?? "--"}
                </Text>
              </Space>
            </Col>
            <Col span={12} style={{ margin: "10px 0" }}>
              <Space direction="vertical" size="large">
                <Space direction="vertical">
                  <Text strong>City</Text>
                  <Text>{organization?.organizationAddress?.city ?? "--"}</Text>
                </Space>
                <Space direction="vertical">
                  <Text strong>zipcode</Text>
                  <Text>
                    {organization?.organizationAddress?.zipcode ?? "--"}
                  </Text>
                </Space>
              </Space>
            </Col>
            <Col span={12} style={{ marginTop: "10px 0" }}>
              <Space direction="vertical" size="large">
                <Space direction="vertical">
                  <Text strong>State</Text>
                  <Text>
                    {organization?.organizationAddress?.state ?? "--"}
                  </Text>
                </Space>
              </Space>
            </Col>
          </Row>
        </Card>
      </IncredableContent>
      <IncredableContent style={{ margin: "30px 20px 0", padding: "0" }}>
        <Card
          bordered={false}
          title="Settings"
          extra={
            <IncredableButton
              type="primary"
              onClick={async () => {
                if (organization?.id && !!orgSettings) {
                  spinnerContext?.showSpinner();
                  await updateOrganizationSettings({
                    id: organization?.id,
                    ...orgSettings,
                  });
                  spinnerContext?.hidespinner();
                  toastContext?.openInfoNotification({
                    message: `organization updated successfully`,
                    placement: "topRight",
                  });
                }
              }}
            >
              Save
            </IncredableButton>
          }
        >
          <Form layout="vertical">
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex" }}
            >
              <Row gutter={16} align="middle">
                <Col span={12}>
                  <Text strong style={{ margin: 0 }}>
                    Facility based user access
                  </Text>
                </Col>
                <Col span={6}>
                  <Radio.Group
                    value={orgSettings?.enableFacilityBasedAccess}
                    onChange={(e) => {
                      setOrgSettings({
                        ...orgSettings,
                        enableFacilityBasedAccess: e.target.value,
                      });
                    }}
                  >
                    <Radio value={true}>Enable</Radio>
                    <Radio value={false}>Disable</Radio>
                  </Radio.Group>
                </Col>
              </Row>
            </Space>
          </Form>
        </Card>
      </IncredableContent>
    </>
  );
}
