import { Row, Col, Breadcrumb } from "antd";
import DocumentDetails from "../versions/document-details";
import DocumentComment from "../versions/document-comments";
import { useForm } from "react-hook-form";
import { skipToken } from "@reduxjs/toolkit/query";
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useGetDocumentQuery } from "../../redux/api/document/document";
import AttachmentViewer from "../../components/attachment-viewer";
import DocumentVersionsV1 from "../versions/document-versions-v1";
import DocumentActiviesV1 from "../versions/document-activities-v1";
import { Tabs } from "@cloudscape-design/components";
import EditProviderEducation from "../../provider/modify/edit-providereducation";

type FormInputType = {
  name: string;
  expirationDate?: string | Date;
  createdDate: string;
  alertDays: number;
  notes: string;
  categoryId: string;
  isVersioned: boolean;
};
export default function EditDocument() {
  const methods = useForm<FormInputType>();
  const { providerId, documentId } = useParams();
  const { data: document } = useGetDocumentQuery(
    !!documentId && !!providerId ? { documentId, providerId } : skipToken
  );

  const [uploadedFile, setUploadedFile] = useState<File>();
  useEffect(() => {
    if (!document) return;
    methods.reset({
      name: document?.name,
      alertDays: document?.alertDays,
      expirationDate: document?.expirationDate,
      notes: document?.notes,
      categoryId: document?.category?.id,
    });
  }, [document, methods]);

  const breadcrumbProps = [
    {
      title: <NavLink to="/safe">Safe</NavLink>,
    },
    {
      title: document?.name || "",
    },
  ];

  return (
    <div
      style={{
        padding: "12px 24px 12px 24px",
      }}
    >
      <div
        style={{
          padding: "0 0 8px 0",
        }}
      >
        <Breadcrumb items={breadcrumbProps} />
      </div>
      <Tabs
        fitHeight
        tabs={[
          {
            label: "Document",
            id: "details",
            content: (
              <Row gutter={24}>
                <Col flex="360px">
                  <DocumentDetails uploadedFile={uploadedFile} />
                </Col>
                <Col flex="auto">
                  <AttachmentViewer
                    attachment={document?.attachment}
                    onFileChange={(file) => {
                      setUploadedFile(file);
                    }}
                    file={uploadedFile}
                  />
                </Col>
              </Row>
            ),
          },
          {
            label: "Versions",
            id: "versions",
            content: <DocumentVersionsV1 />,
          },
          {
            label: "Audit logs",
            id: "aduitlogs",
            content: <DocumentActiviesV1 />,
          },
          {
            label: "Comments",
            id: "comments",
            content: <DocumentComment />,
          },
        ]}
      />
    </div>
  );
}
